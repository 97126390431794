import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Header from "../common/Header";
 import "./order.css"
import { Helmet } from "react-helmet-async";
import Footer from "../common/Footer-without-ISI";

export const SupportResources = () => {
    return (
        <>
  
        <Helmet>
           <title>Order IHEEZO</title>
           <meta name="description" value="IHEEZO (chloroprocaine) is a low-viscosity gel supplied in a sterile, single-use unit. It contains 24 mg of chloroprocaine hydrochloride per unit (800 mg). Each package ordered consists of one unit." />
           <meta name="keywords" value="Order IHEEZO, chloroprocaine for ocular anesthesia, ocular surface anesthesia, cataract surgery anesthetic, ophthalmic anesthetic" />
        </Helmet>
  
           <section className="pagetitle pagetitleCONTACT">
              <div className="container sr-main">
                 <div className="pagetitle-text">
                    <h1>Support Resources</h1>
                    </div>
                 <div className="pg-icon-1 d-b-mob"><img src="img/pg-icon-1.png" alt="" /></div>
                 <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="" /></div>
                 <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="" /></div>
              </div>
           </section>
           {/* <!-- ==============================PAGETITLE SECTION--> */}
           <section className="ab-sec1 order">
              <div className="container">
                    <div className="order-row-sr">
                        <div className="hand" style={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom:0}}>
                        <img src="img/hands.svg" />
                        </div>
             
                             <div className="order-box help-resources top-margin">
                            <div>
                                <img src="img/hrwcaresicon-iheezo.svg" />
                                </div>
                          <div className="sr-box"><p className="E9BE65 sr-head">Harrow Cares helps you use IHEEZO with confidence</p><p className="sr-des">Enroll via Fax, online at HarrowCares.com, or find our enrollment form on PX Technology. Put the focus back on your patients.</p></div>
                       </div>
                     </div>
          
                    


                    <div className="page-des-sr sr-m  bottom-margin top-margin">
                    A one-stop personalized support service for you,<br className="none"/> your office staff, and your patients
                    </div>

                    <div className="bottom-margin" style={{display: "flex", flexWrap: "wrap", gap: "30px"}}>
                     <table class="responsive-table extramr">
                        <thead>
                            <tr className="call-center-assistance-1">
                                <th>Easy Enrollment</th>
                            </tr>
                        </thead>
                        <tbody className="ACE0F9">
                            <tr>
                                <td>
                                    <p className="stream" >
                                        Streamlined enrollment for benefit investigation and product coverage information
                                        </p>
                                        <p className="enroll-easy">
                                    <ul className="ul-cl">
                                        <li>
                                            <a href="tel:8664769462">866-HROWINC (476-9462)</a> 8:00am – 8:00pm ET, M-F
                                        </li>
                                        <li>
                                            <span style={{display:"inline-flex"}}>Provider Portal:&nbsp;</span>
                                             <a href="https://www.harrowcaresproviderportal.com" target="_blank" rel="noopener noreferrer">
                                                www.harrowcaresproviderportal.com
                                            </a> 
                                        </li>
                                        <li>Enrollment via PX Technology integration</li>
                                    </ul></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
                     <table class="responsive-table">
                        <thead>
                            <tr className="call-center-assistance-1">
                                <th>Real-time updates on the progress of your patients’ support</th>
                            </tr>
                        </thead>
                        <tbody className="ACE0F9">
                            <tr>
                                <td>
                                    <p className="login-text">
                                        Log into the Harrow Cares Provider Portal
                                    </p>
                                    <p>
                                        <a href="https://www.harrowcaresproviderportal.com" target="_blank" rel="noopener noreferrer"><button className="hcare-bt">Harrow Cares Provider Portal</button></a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="bottom-margin" style={{display: "flex", flexWrap: "wrap", gap: "16px"}}>
                <table class="responsive-table2">
                   <thead>
                       <tr className="call-center-assistance-1">
                           <th>Assistance for you and your patients from our dedicated call center in less than 30 seconds</th>
                       </tr>
                   </thead>
                   <tbody className="ACE0F9">
                       <tr>
                           <td className="assis">
                           Receive personalized access and cost support through a Harrow Cares specialist
                           </td>
                       </tr>
                   </tbody>
               </table>
           
        
           </div>
           <div className="bottom-margin" style={{display: "flex", flexWrap: "wrap", gap: "16px"}}>
           <table class="responsive-table2">
              <thead>
                  <tr className="call-center-assistance-1">
                      <th>Harrow Cares Commercial Copay Program</th>
                  </tr>
              </thead>
              <tbody className="ACE0F9">
                  <tr>
                                    <td>
                                        <p  className="copay-ul">
                    
                   <ul> <li>  Available to commercially insured patients, excludes patients enrolled in Medicaid, Medicare, Tricare, etc.</li>
                   <li>Pay as little as $0</li>
                   <li>  Program Maximum Annual Benefit: $5,000</li>
                   <li> Program Enrollment Period: Start Date through December 31 of that calendar year</li>
                   <li>  Once enrolled, a claim should be sent to the program within 180 days of the date of treatment</li>
                   <li>  If a patient was treated within the last 120 days, the program can look back and approve copay assistance. Enroll immediately using the Enrollment Form and attaching an EOB from the insurer </li>
                      </ul>   </p>           
                      </td>
                  </tr>
              </tbody>
          </table>
      
   
                    </div>
                    <div className="page-des-sr bottom-margin top-margin">
                    Harrow Cares, so we give prescribers and patients an easy,<br className="none"/> fast, and simple solution to accessing therapy
                    </div>
                    <p className="bottom-info">
                    *Subject to program terms and limitations.
                    Please contact 
                    <a href="tel:18664769462">&nbsp;1-866-HROWINC (476-9462)&nbsp;</a> 
                    for additional details.
                  </p>
                  
                </div>
      
           </section>
           {/* <!-- ==============================APPROVED USE SECTION--> */}
           <Footer />
        </>
     )
}
